import * as React from 'react';
import { ReactNode } from 'react';
import { cn } from '~/utils/tw';

interface IconProps {
  size: number;
}

const EmptyStateIcon: React.FC<{
  children: React.ReactElement<IconProps>;
  className?: React.HTMLProps<HTMLElement>['className'];
  size?: number;
  scale?: boolean;
}> = ({ children, size, className, scale = true }) => (
  <div
    className={cn(
      'mb-4 flex h-16 w-16 items-center justify-center rounded-full bg-homy-gray-lighter/25 md:h-32 md:w-32',
      className,
    )}
  >
    <div
      className={cn(' text-homy-gray-light', scale && 'scale-50 md:scale-100')}
    >
      {React.cloneElement(children, { size: size ?? 50 })}
    </div>
  </div>
);

const EmptyStateTitle: React.FC<{ children: string }> = ({ children }) => (
  <p className="mb-4 text-lg font-bold text-homy-gray-light md:text-2xl">
    {children}
  </p>
);

const EmptyStateDescription: React.FC<{
  children: string;
  className?: React.HTMLProps<HTMLElement>['className'];
}> = ({ children, className }) => (
  <p className={cn('mb-4 text-xs text-homy-gray-light', className)}>
    {children}
  </p>
);

const EmptyStateButton: React.FC<{
  children: ReactNode;
}> = ({ children }) => (
  <div className="flex min-w-full justify-center">{children}</div>
);

const EmptyState = ({
  className,
  children,
}: React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>) => (
  <div
    className={cn(
      'flex w-52 flex-col items-center justify-center self-center p-1 py-4 text-center',
      className,
    )}
  >
    {children}
  </div>
);

EmptyState.displayName = 'EmptyState';
EmptyState.Icon = EmptyStateIcon;
EmptyState.Title = EmptyStateTitle;
EmptyState.Description = EmptyStateDescription;
EmptyState.Button = EmptyStateButton;

export { EmptyState };
